export default{
    namespaced:true,
    state:{
        key:'',
        lujin:'',
    },
    mutations:{
        setKey(state,payload){
            state.key=payload;
        },
        setLijin(state,payload){
            state.lujin=payload;
        },
        clearKey(state){
            state.key='';
        }
    },
    actions:{}
}