export default{
    namespaced:true,
    state:{
        equipMemberId:'',
    },
    mutations:{
        setEq(state,payload){
            state.equipMemberId=payload;
        },
        clearuserEq(state){
            state.equipMemberId="";

        }
    },
    actions:{}
}