// import { dynamicRouters } from '../../router/DynamicRoutes';//前端路由

// import {ruleRoutes} from '../../utils/ruleRoutes';
import {api} from '../../api1/config';

export default {
    namespaced: true,
    state: {
        menuList: [], //路由导航
    },
    mutations: {
        setMuenList(state, payload) {
            state.menuList = payload
        },
        clearMenuList(state) {
            state.menuList = []
        }
    },
    actions: {
        //请求后台动态路由 
        //函数：promise   es6:async await  
        async getActionsRoutes({commit}) {
           // console.log(commit,rootState);
            //1.获取后台动态路由
            // let that = this
            // that.token = JSON.parse(sessionStorage.getItem('token'))
            // console.log('token', that.token)
            // let routes=await that.$http({
            //     url: '/api/menu/getUserMenu',
            //     method: "POST",
            //     headers: {
            //         'Content-Type': "application/json;charset=UTF-8",
            //         //把token放到请求头才能请求，这里的'Bearer '表示是后台希望更加的安全，依据后台给的信息看到底是加还是不加
            //         // 'Authorization': 'Bearer ' + that.token,
            //         'token': that.token
            //     }
            // })
            //     .then(res => {
            //         console.log('权限', res.data)
            //         if (res.data.code == 200) {
            //             sessionStorage.setItem('jurisdiction', JSON.stringify(res.data.result));
            //         }

            //     })
            let routes = await api.getDongLuYou();
            commit('setMuenList', routes.data.result)
            //console.log('---后台路由--', routes.data.result);
            //console.log('---前台路由--', dynamicRouters);
            // //2.获取前端路由 
            //3.前后端路由匹配模式 
             //let menuArr = ruleRoutes(dynamicRouters, routes.data.result)
             //console.log('需要的路由：', menuArr);
            // //4.存储vuex
            // commit('setMuenList', menuArr)
            // //5.获取动态路由 给layout组件加动态路由
            // let children = baseeRouter[0].children;
            // children.push(...menuArr);
            // // console.log(children);
            // // console.log('baseeRouter',baseeRouter);
            // //6.路由数组合并新的路由 
            // //路由合并的方法
            // router.addRoutes(baseeRouter)

        }


    }
}