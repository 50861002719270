import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
// import store from '../store'
//解决重复跳转路由报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)

export const routes = [

  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/forgetpassword',
    name: 'forgetpassword',
    component: () => import('../views/forgetpassword.vue')
  },
  {
    path: '/changepassword',
    name: 'changepassword',
    component: () => import('../views/changepassword.vue')
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import('../views/admin.vue')
  },
  {
    path: '/largesize',
    name: 'admin',
    component: () => import('../views/largesize.vue')
  },
  //字典
  {
    path: '/dictionary',
    name: 'dictionary',
    component: () => import('@/views/admin.vue'),
    meta: {
      title: '字典'
    },
    children: [
      {
        path: 'dictionary',
        name: 'dictionary',
        component: () => import('@/views/Dictionary/Dictionary.vue'),
        meta: {
          title: '字典管理',
          requiresAuth: true
        }
      },
      {
        path: 'sondictionary',
        name: 'sondictionary',
        component: () => import('@/views/Dictionary/SonDictionary.vue'),
        meta: {
          title: '子字典',
          requiresAuth: true
        }
      }
    ]
  },
  // 系统查询
  {
    path: '/system_query',
    name: 'system_query',
    component: () => import('@/views/admin.vue'),
    meta: {
      title: '系统查询',
    },
    children: [
      {
        path: 'central',
        name: 'central',
        component: () => import('@/views/system_query/central.vue'),
        meta: {
          title: '中心看板',
          requiresAuth: true,
        }
      },
      {
        path: 'life',
        name: 'life',
        component: () => import('@/views/system_query/life.vue'),
        meta: {
          title: '生活服务统计',
          requiresAuth: true
        }
      },
      {
        path: 'feedback',
        name: 'feedback',
        component: () => import('@/views/system_query/feedback.vue'),
        meta: {
          title: '反馈建议统计',
          requiresAuth: true
        }
      },
      {
        path: 'user',
        name: 'user',
        component: () => import('@/views/system_query/user.vue'),
        meta: {
          title: '设备用户信息',
          requiresAuth: true
        }
      },
      {
        path: 'service',
        name: 'service',
        component: () => import('@/views/system_query/service.vue'),
        meta: {
          title: '客服数据',
          requiresAuth: true
        }
      },
      {
        path: 'calllog',
        name: 'calllog',
        component: () => import('@/views/system_query/calllog.vue'),
        meta: {
          title: '通话记录',
          requiresAuth: true
        }
      },
      {
        path: 'calllogNo',
        name: 'calllogNo',
        component: () => import('@/views/system_query/calllogNo.vue'),
        meta: {
          title: '通话记录(未接通)',
          requiresAuth: true
        }
      },
      {
        path: 'fourRecord',
        name: 'fourRecord',
        component: () => import('@/views/system_query/fourRecord.vue'),
        meta: {
          title: '400记录',
          requiresAuth: true
        }
      },
      {
        path: 'rescueQuery',
        name: 'rescueQuery',
        component: () => import('@/views/system_query/rescueQuery.vue'),
        meta: {
          title: '救援信息查询',
          requiresAuth: true
        }
      },
      {
        path: 'alarmInfoQuery',
        name: 'alarmInfoQuery',
        component: () => import('@/views/system_query/alarmInfoQuery.vue'),
        meta: {
          title: '报警信息查询',
          requiresAuth: true
        }
      },
      {
        path: 'workOrderQuery',
        name: 'workOrderQuery',
        component: () => import('@/views/system_query/workOrderQuery.vue'),
        meta: {
          title: '工单查询',
          requiresAuth: true
        }
      }
    ]
  },
  // 内容管理
  {
    path: '/content',
    name: 'content',
    component: () => import('@/views/admin.vue'),
    meta: {
      title: '内容管理',
    },
    children: [
      {
        path: 'general',
        name: 'general',
        component: () => import('@/views/content/general.vue'),
        meta: {
          title: '通用知识库管理',
          requiresAuth: true
        }
      },
      {
        path: 'major',
        name: 'major',
        component: () => import('@/views/content/major.vue'),
        meta: {
          title: '专用知识库管理',
          requiresAuth: true
        }
      },
      {
        path: 'realtimedata',
        name: 'realtimedata',
        component: () => import('@/views/content/realtimedata.vue'),
        meta: {
          title: '定时消息推送',
          requiresAuth: true
        }
      }
    ]
  },
  //前端错误日志记录
  {
    path: '/ErrorLog',
    name: 'ErrorLog',
    component: () => import('@/views/admin.vue'),
    meta: {
      title: '错误日志',
    },
    children: [
      {
        path: 'errorLogs',
        name: 'errorLogs',
        component: () => import('@/views/ErrorLog/errorLogs.vue'),
        meta: {
          title: '前端错误日志',
          requiresAuth: true
        }
      }
    ]
  },
  // 呼叫中心
  {
    path: '/call_center',
    name: 'call_center',
    component: () => import('@/views/admin.vue'),
    meta: {
      title: '呼叫中心',
    },
    children: [
      {
        path: 'shout',
        name: 'shout',
        component: () => import('@/views/call_center/shout.vue'),
        meta: {
          title: '呼叫中心',
          requiresAuth: true
        }
      },
      {
        path: 'call_center',
        name: 'call_center',
        component: () => import('@/views/call_center/call_center.vue'),
        meta: {
          title: '外呼中心',
          requiresAuth: true
        }
      },
      {
        path: 'service',
        name: 'service',
        component: () => import('@/views/call_center/service.vue'),
        meta: {
          title: '客服中心',
          requiresAuth: true
        }
      },
      {
        path: 'four',
        name: 'four',
        component: () => import('@/views/call_center/four.vue'),
        meta: {
          title: '400中心',
          requiresAuth: true
        }
      },
      {
        path: 'serviceLook',
        name: 'serviceLook',
        component: () => import('@/views/call_center/serviceLook.vue'),
        meta: {
          title: '客服看板',
          requiresAuth: true
        }
      },
      {
        path: 'commonKnowledge',
        name: 'commonKnowledge',
        component: () => import('@/views/call_center/commonKnowledge.vue'),
        meta: {
          title: '常用知识',
          requiresAuth: true
        }
      },
      {
        path: 'seekhelp',
        name: 'seekhelp',
        component: () => import('@/views/call_center/seekhelp.vue'),
        meta: {
          title: '紧急求助',
          requiresAuth: true
        }
      },
      {
        path: 'agency',
        name: 'agency',
        component: () => import('@/views/call_center/agency.vue'),
        meta: {
          title: '待办工单',
          requiresAuth: true
        }
      },
      {
        path: 'callLoss',
        name: 'callLoss',
        component: () => import('@/views/call_center/callLoss.vue'),
        meta: {
          title: '呼损数',
          requiresAuth: true
        }
      }
    ]
  },
  // 系统维护
  {
    path: '/system_maintain',
    name: 'system_maintain',
    component: () => import('@/views/admin.vue'),
    meta: {
      title: '系统维护',
    },
    children: [
      {
        path: 'usermanage',
        name: 'usermanage',
        component: () => import('@/views/system_maintain/usermanage.vue'),
        meta: {
          title: '用户管理',
          requiresAuth: true
        }
      },

      {
        path: 'subcenter',
        name: 'subcenter',
        component: () => import('@/views/system_maintain/subcenter.vue'),
        meta: {
          title: '子中心管理',
          requiresAuth: true
        }
      },
      {
        path: 'parameter',
        name: 'parameter',
        component: () => import('@/views/system_maintain/parameter.vue'),
        meta: {
          title: '参数配置',
          requiresAuth: true
        }
      },
      {
        path: 'terminal',
        name: 'terminal',
        component: () => import('@/views/system_maintain/terminal.vue'),
        meta: {
          title: '终端管理',
          requiresAuth: true
        }
      },
      {
        path: 'edition',
        name: 'edition',
        component: () => import('@/views/system_maintain/edition.vue'),
        meta: {
          title: '版本管理',
          requiresAuth: true
        }
      },
      {
        path: 'backups',
        name: 'backups',
        component: () => import('@/views/system_maintain/backups.vue'),
        meta: {
          title: '备份管理',
          requiresAuth: true
        }
      },
      {
        path: 'journal',
        name: 'journal',
        component: () => import('@/views/system_maintain/journal.vue'),
        meta: {
          title: '系统日志',
          requiresAuth: true
        }
      },
      {
        path: 'product',
        name: 'product',
        component: () => import('@/views/system_maintain/product.vue'),
        meta: {
          title: '产品管理',
          requiresAuth: true
        }
      },
      {
        path: 'salesgroup',
        name: 'salesgroup',
        component: () => import('@/views/system_maintain/salesgroup.vue'),
        meta: {
          title: '销售分组管理',
          requiresAuth: true
        }
      },
      {
        path: 'cardManagement',
        name: 'cardManagement',
        component: () => import('@/views/system_maintain/cardManagement.vue'),
        meta: {
          title: '号卡管理',
          requiresAuth: true
        }
      },
      {
        path: 'outbound',
        name: 'outbound',
        component: () => import('@/views/system_maintain/outbound.vue'),
        meta: {
          title: '出库记录',
          requiresAuth: true
        }
      },
    ]
  },
  // 后台配置
  {

    path: '/console',
    name: 'backstage',
    component: () => import('@/views/admin.vue'),
    meta: {
      title: '后台配置',
    },
    children: [
      {
        path: 'menu',
        name: 'menu',
        component: () => import('../views/backstage/menu.vue'),
        meta: {
          title: '菜单管理',
          requiresAuth: true
        }
      },
      {

        path: 'dict',
        name: 'dict',
        component: () => import('../views/backstage/dict.vue'),
        meta: {
          title: '字典管理',
          requiresAuth: true
        }
      },
      {

        path: 'org',
        name: 'org',
        component: () => import('../views/backstage/org.vue'),
        meta: {
          title: '组织机构',
          requiresAuth: true
        }
      },
      {
        path: 'user',
        name: 'user',
        component: () => import('../views/backstage/user.vue'),
        meta: {
          title: '用户管理',
          requiresAuth: true
        }
      },
      {
        path: 'role',
        name: 'role',
        component: () => import('../views/backstage/role.vue'),
        meta: {
          title: '角色管理',
          requiresAuth: true
        }
      },
      {
        path: 'addItemOne',
        name: 'addItemOne',
        component: () => import('../views/backstage/addItemOne.vue'),
        meta: {
          title: 'API管理',
          requiresAuth: true
        }
      },
      {
        path: 'single',
        name: 'single',
        component: () => import('../views/backstage/single.vue'),
        meta: {
          title: '大屏免登录管理',
          requiresAuth: true
        }
      },
      {
        path: 'checkup',
        name: 'checkup',
        component: () => import('../views/backstage/checkup.vue'),
        meta: {
          title: '体检一体机',
        }
      },
    ]

  },
  //手机应用
  {
    path: '/cellphoneApplication',
    name: 'cellphoneApplication',
    component: () => import('@/views/admin.vue'),
    meta: {
      title: '手机应用',
    },
    children: [
      {
        path: 'appUser',
        name: 'appUser',
        component: () => import('@/views/cellphoneApplication/appUser.vue'),
        meta: {
          title: 'APP用户管理',
          requiresAuth: true
        }
      },
      {
        path: 'family',
        name: 'family',
        component: () => import('@/views/cellphoneApplication/family.vue'),
        meta: {
          title: '家庭管理',
          requiresAuth: true
        }
      },
      {
        path: 'advertising',
        name: 'advertising',
        component: () => import('@/views/cellphoneApplication/advertising.vue'),
        meta: {
          title: '广告管理',
          requiresAuth: true
        }
      },
      {
        path: 'helpfeedback',
        name: 'helpfeedback',
        component: () => import('@/views/cellphoneApplication/helpfeedback.vue'),
        meta: {
          title: '帮助反馈',
          requiresAuth: true
        }
      },
      {
        path: 'pushNotification',
        name: 'pushNotification',
        component: () => import('@/views/cellphoneApplication/pushNotification.vue'),
        meta: {
          title: '推送通知',
          requiresAuth: true
        }
      },
      {
        path: 'versions',
        name: 'versions',
        component: () => import('@/views/cellphoneApplication/versions.vue'),
        meta: {
          title: 'APP版本',
          requiresAuth: true
        }
      },
      {
        path: 'payManagement',
        name: 'payManagement',
        component: () => import('@/views/cellphoneApplication/payManagement.vue'),
        meta: {
          title: '付费管理',
          requiresAuth: true
        }
      },
      {
        path: 'redeemCode',
        name: 'redeemCode',
        component: () => import('@/views/cellphoneApplication/redeemCode.vue'),
        meta: {
          title: '兑换码管理',
          requiresAuth: true
        }
      },
      {
        path: 'configuration',
        name: 'configuration',
        component: () => import('@/views/cellphoneApplication/configuration.vue'),
        meta: {
          title: 'APP配置',
          requiresAuth: true
        }
      },
      {
        path: 'dataStatistics',
        name: 'dataStatistics',
        component: () => import('@/views/cellphoneApplication/dataStatistics.vue'),
        meta: {
          title: '数据统计',
          requiresAuth: true
        }
      },
    ]
  },
  //智慧管理
  {
    path: '/intelligent',
    name: 'intelligent',
    component: () => import('@/views/admin.vue'),
    meta: {
      title: '智慧管理',
    },
    children: [
      {
        path: 'program',
        name: 'program',
        component: () => import('@/views/intelligent/program.vue'),
        meta: {
          title: '有声节目维护',
          requiresAuth: true
        }
      },
      {
        path: 'radiolist',
        name: 'radiolist',
        component: () => import('@/views/intelligent/radiolist.vue'),
        meta: {
          title: '电台节目维护',
          requiresAuth: true
        }
      },
      {
        path: 'repository',
        name: 'repository',
        component: () => import('@/views/intelligent/repository.vue'),
        meta: {
          title: '知识库',
          requiresAuth: true
        }
      },
      {
        path: 'skill',
        name: 'skill',
        component: () => import('@/views/intelligent/skill.vue'),
        meta: {
          title: '技能管理',
          requiresAuth: true
        }
      },
      {
        path: 'advert',
        name: 'advert',
        component: () => import('@/views/intelligent/advert.vue'),
        meta: {
          title: '广告管理',
          requiresAuth: true
        },
      },
      {
        path: 'rbtHomemenu',
        name: 'rbtHomemenu',
        component: () => import('@/views/intelligent/rbtHomemenu.vue'),
        meta: {
          title: '机器人首页菜单管理',
          requiresAuth: true
        }
      },
      {
        path: 'classification',
        name: 'classification',
        component: () => import('@/views/intelligent/classification.vue'),
        meta: {
          title: '养生知识分类',
          requiresAuth: true
        }
      },
    ]
  },
  //智能应用
  {
    path: '/IntelligentApp',
    name: 'IntelligentApp',
    component: () => import('@/views/admin.vue'),
    meta: {
      title: '智能应用',
    },
    children: [
      {
        path: 'AIBroadcast',
        name: 'AIBroadcast',
        component: () => import('@/views/IntelligentApp/AIBroadcast.vue'),
        meta: {
          title: 'AI播报管理',
          requiresAuth: true
        }
      },
    ]
  },
  //企业账户
  {
    path: '/businessAcc',
    name: 'businessAcc',
    component: () => import('@/views/admin.vue'),
    meta: {
      title: '企业账户',
    },
    children: [
      {
        path: 'paymentManage',
        name: 'paymentManage',
        component: () => import('@/views/businessAcc/paymentManage.vue'),
        meta: {
          title: '付费管理',
          requiresAuth: true
        }
      },
      {
        path: 'consumptionInq',
        name: 'consumptionInq',
        component: () => import('@/views/businessAcc/consumptionInq.vue'),
        meta: {
          title: '消费查询',
          requiresAuth: true
        }
      },
      {
        path: 'accountInq',
        name: 'accountInq',
        component: () => import('@/views/businessAcc/accountInq.vue'),
        meta: {
          title: '账户查询',
          requiresAuth: true
        }
      },
    ]
  },
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})
// //全局守卫
router.beforeEach((to, from, next) => {
  next()
  console.log(to, from, next);
})

export default router
