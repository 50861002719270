import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// 取消默认样式
import './assets/reset.css'
// 引入element ui
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import { Message } from "element-ui";
import { MessageBox } from "element-ui";
Vue.use(ElementUI);
// 引入ant-design
// import 'ant-design-vue'
// import 'ant-design-vue/dist/antd.css'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
Vue.use(Antd);
Vue.prototype.$message = Message
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$antdconfirm = Vue.prototype.$confirm
//引入音频
import TRTC from 'trtc-js-sdk';
Vue.prototype.$TRTC = TRTC;
// 引入字体
// import './styles/index.less';
import global from './utils/global'
Vue.prototype.global = global
// 引入echarts
import * as echarts from 'echarts';
Vue.prototype.$echarts = echarts;
// 引入axios
import axios from 'axios';
Vue.prototype.$http = axios;
// const service = axios.create({
//   baseURL:'/api',//根路径
//   timeout:500000 //请求超时
// })
// Vue.prototype.$service=service;
// 时间总线
Vue.prototype.$bus = new Vue()
// 时间格式化
import dateTime from "./api/dateTime.js"
for (let key in dateTime) {
  Vue.filter(key, dateTime[key]);
}

Vue.config.productionTip = false;
// Vue.config.errorHandler = function (err, vm, info) {
//   // 参数解析：
//   // 1.err：错误对象；
//   // 2.vm：Vue实例；
//   // 3.info：Vue 特定的错误信息，比如错误所在的生命周期钩子
//   // 错误被捕获后，不会再在控制台输出错误信息，可以补输出：
//   console.error(err, vm, info, '查看输出的错误')
//   // 然后完成错误上报，一般是发送到一个收集错误的 API 接口
//   // 如有必要，你还可以把 navigator 对象(客户端信息)一起上报
// }
// window.addEventListener('unhandledrejection', (error) => {
//   console.log(error, 'error')
//   // 区分是否是js错误
//   if (error.message) {
//     console.error('监测到E1003错误js', error);
//     // errorHandler('E1003', error.message, error.filename)
//   } else {
//     console.error('监测到E1004错误静态资源', error);
//     // errorHandler('E1004', error.target.currentSrc, error.filename)
//   }
// }, true)
//因为接口报错需要手动捕获 创建公用方法
//错误1
const errorHandler = (err, vm, info) => {
  console.log(vm, info, 'vmInfo')
  if (err.isAxiosError) {
    //axios请求错误
  } else {
    //js语法错误
    console.log('err:', err.toString())
  }
}
//调用
Vue.config.errorHandler = errorHandler
//axios手动捕获使用 绑定 prototype
Vue.prototype.$throw = (error) => errorHandler(error, this)
//错误2
window.addEventListener('unhandledrejection', (error) => {
  console.log(error, 'error.message')
  // 区分是否是js错误
  if (error.message) {
    console.error('监测到E1003错误js', error);
    // errorHandler('E1003', error.message, error.filename)
  } else {
    console.error('监测到E1004错误静态资源', error);
    console.log(error.reason.message, 'axios未走通时的报错')
    // let that = this
    let routerList = router.app._route;
    let RouteName = [];
    routerList.matched.forEach(element => {
      if (Object.keys(element.meta).length != 0) {
        RouteName.push(element.meta.title);
      } else {
        RouteName = [];
      }
    });
    console.log(RouteName.length, 'RouteName')
    let routerName = '';
    if (RouteName.length != 0) {
      routerName = RouteName[0] + '-' + RouteName[1]
    }
    let loginuser = JSON.parse(localStorage.getItem("loginuser"))
    axios.get('/too/center/weblog/save', {
      params: {
        callback: "",
        errorCode: 'E1004',
        errorExplain: error.reason.message,
        interfaceUrl: '',
        menuName: routerName,
        menuUrl: router.app._route.path,
        params: '',
        source: "客服系统",
        userId: loginuser.id,
        userName: loginuser.name,
      }
    })
      .then((res) => {
        console.log(res, '成功')
      })
      .catch(error => {
        console.log(error, '失败')
      })


    // errorHandler('E1004', error.target.currentSrc, error.filename)
  }
}, true)

window.onerror = function (message, source, lineno, colno, error) {
  console.log(message, source, lineno, colno, error, '报错显示行数')
}

// //获取本地存储
// let token = JSON.parse(localStorage.getItem('token'));
// if (token) {
//   store.commit('LoginModule/setToken', token)
// }

// let menuList = JSON.parse(sessionStorage.getItem('houtai'));
// if (menuList) {
//   store.commit('routeModule/setMuenList', menuList)
// }
//防抖处理-立即执行
const on = Vue.prototype.$on
Vue.prototype.$on = function (event, func) {
  let timer
  let flag = true
  let newFunc = func
  if (event === 'click') {
    newFunc = function () {
      if (flag) {
        func.apply(this, arguments)
        flag = false
      }
      clearTimeout(timer)
      timer = setTimeout(function () {
        flag = true
      }, 500)
    }
  }
  on.call(this, event, newFunc)
}
Vue.config.errorHandler = function (err, vm, info) {
  // let obj={
  //   message, // 异常信息
  //   name, // 异常名称
  //   script,  // 异常脚本url
  //   line,  // 异常行号
  //   column,  // 异常列号
  //   stack  // 异常堆栈信息
  // } = err;
  // 参数解析：
  // 1.err：错误对象；
  // 2.vm：Vue实例；
  // 3.info：Vue 特定的错误信息，比如错误所在的生命周期钩子
  // 错误被捕获后，不会再在控制台输出错误信息，可以补输出：
  console.log(vm, '查询vm输出')
  console.log(info, '查询info输出')
  console.error(err.message, err.name, err.script, '收集的错误信息')
  // 然后完成错误上报，一般是发送到一个收集错误的 API 接口
  // 如有必要，你还可以把 navigator 对象(客户端信息)一起上报
}
// Vue.config.warnHandler = function (msg, vm, trace) {
//   // `trace` 是组件的继承关系追踪
//   console.warn('warn123', msg, vm, trace)
//   // 逻辑处理
// }
window.addEventListener('unhandledrejection', (error) => {
  // 区分是否是js错误
  if (error.message) {
    console.error('监测到E1003错误js', error);
    // errorHandler('E1003', error.message, error.filename)
  } else {
    console.error('监测到E1004错误静态资源', error);
    // errorHandler('E1004', error.target.currentSrc, error.filename)
  }
}, true)
window.onerror = function (errorMessage, scriptURI, lineNumber, columnNumber, errorObj) {
  var context = "错误路径：" + window.location.href + ";"
    + "错误信息：" + errorMessage + ";"
    + "出错文件：" + scriptURI + ";"
    + "出错行号：" + lineNumber + ";"
    + "出错列号：" + columnNumber + ";"
    + "错误详情：" + errorObj + "。";
  console.log(context, 'context')
}
// 将cancel,挂载到vue原型上
Vue.prototype.cancel = function () {
  // 获取缓存的 请求取消标识 数组，取消所有关联的请求
  let cancelArr = window.axiosCancel;
  cancelArr = cancelArr || [];
  cancelArr.forEach((ele, index) => {
    ele.cancel("取消了请求")  // 在失败函数中返回这里自定义的错误信息
    delete window.axiosCancel[index]
  })
}

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
}).$mount('#app')