<template>
  <div class="logining">
    <div ref="clink" style="display: none"></div>
    <!-- 头 -->
    <div class="header">
      <div class="imgs">
        <img src="../assets/logo1.png" alt />
      </div>
      <div class="title">
        <span>健康数据管理中心</span>
      </div>
    </div>
    <!-- 表单 -->
    <div class="main">
      <el-form
        :model="ruleForm"
        status-icon
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item prop="account">
          <span slot="label">
            <img class="image" src="../assets/account.png" alt />
          </span>
          <el-input
            v-model="ruleForm.name"
            placeholder="请输入用户名"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item prop="pwd">
          <span slot="label">
            <img class="image" src="../assets/password.png" alt />
          </span>
          <el-input
            type="password"
            v-model="ruleForm.password"
            placeholder="请输入密码"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <template v-if="!show">
          <el-form-item class="imagestext" prop="pwd">
            <el-input
              placeholder="输入验证码"
              v-model="ruleForm.code"
              autocomplete="off"
            ></el-input>
            <img :src="imageText" alt="" @click="imgtext" />
          </el-form-item>
        </template>
      </el-form>
      <div class="but">
        <el-button type="primary" @click="logining('ruleForm')">登录</el-button>
        <span class="forgetpwd" @click="forgetpwd">忘记密码</span>
      </div>
    </div>
  </div>
</template>

<script>
import base from "../api1/config";
import { mapMutations } from "vuex";
import { api } from "../api1/config";
// import { apiShout } from "../api1/configShout";
// import {DynamicRoutes} from '../router/dynamicRouters';
export default {
  data() {
    return {
      jin: "",
      ruleForm: {
        name: "",
        password: "",
        // code: "",
      },
      result: "",
      show: true,
      imageText: "",
      rules: {
        Account: [{ trigger: "blur" }],
        pass: [{ trigger: "blur" }],
      },
      token: "",
      options: {
        isAutoLogin: true, //隐藏登录界面
        showHold: true,
        showConsult: true,
        showTransfer: true,
        showSatisfaction: true,
        showMute: true,
      },
      canLogin: false,
    };
  },
  created() {
    this.result = JSON.parse(window.sessionStorage.getItem("result"));
    // console.log(this.result)
    if (this.result > 2) {
      this.show = false;
      this.graphicCode();
    }
    // window.onload = function () {
    //   alert("所有资源加载完毕！");
    // };
    // console.log("document.readyState:", document.readyState);
    this.jin = setInterval(() => {
      if (document.readyState === "complete") {
        this.canLogin = true;
      } else {
        this.canLogin = false;
      }
    }, 1000);
  },
  // watch:{
  //   //路由改变的时候监听
  //   $route(to,from) {
  //     console.log('===0',to,from);
  //     if(to.path=='/system_query/central'){
  //       this.$router.go(0);
  //     }
  //   },
  // },
  mounted() {
    //绑定事件
    window.addEventListener("keydown", this.keyDown);
    let domContainer = this.$refs.clink;
    new ClinkToolbar(this.options, domContainer, this.callback);
    //清除缓存
    window.localStorage.removeItem("houtai");
    window.localStorage.removeItem("loginuser");
    window.localStorage.removeItem("hash");
    window.localStorage.removeItem("file");
    window.localStorage.removeItem("loglevel");
    window.localStorage.removeItem("token");
    window.localStorage.removeItem("isNew");
    window.localStorage.removeItem("cno");
    window.localStorage.removeItem("createOrder");
    window.localStorage.removeItem("cusSer");
    window.sessionStorage.clear();
  },
  destroyed() {
    window.removeEventListener("keydown", this.keyDown, false);
    console.log("走到destroyed");
  },
  methods: {
    callback() {},
    ...mapMutations("routeModule", ["setMuenList"]),
    ...mapMutations("LoginModule", ["setToken"]),
    // 图文验证接口
    graphicCode() {
      let that = this;
      that
        .$http({
          url: "/too/login/graphicCode",
          params: { name: this.ruleForm.name },
          method: "POST",
          responseType: "blob", // 表明返回服务器返回的数据类型
        })
        .then((res) => {
          console.log("图文验证", res);
          if (res.data) {
            //如果返回的有值，则使用返回的底图
            // 先将二进制的流转为blob，注意后面type一定要设置为图片格式
            let blob = new Blob([res.data], { type: "image/png" });
            var Fr = new FileReader();
            Fr.readAsDataURL(blob);
            Fr.onload = (event) => {
              //这个就是转换为的base64图片地址
              that.imageText = event.target.result;
              // console.log(that.imageText)
            };
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 回车登录
    keyDown(e) {
      //如果是回车则执行登录方法
      if (e.keyCode == 13) {
        this.logining();
      }
    },
    // 登录跳转首页
    logining() {
      if (this.canLogin == true) {
        let that = this;
        if (that.ruleForm.name == "" || that.ruleForm.password == "") {
          that.$message.error("请输入用户名和密码");
        } else {
          console.log("路径：", base.login);
          that
            .$http({
              url: base.login,
              params: that.ruleForm,
              method: "POST",
              headers: {
                "Content-Type": "application/json;charset=UTF-8",
              },
            })
            .then((res) => {
              console.log("登录：", res.data);
              // let ids = res.data.result.id;
              // let Names = res.data.result.name;
              if (res.data.code === 200 && res.data.result.cusSerSign == "1") {
                //1.9.获取登录客服用户天润配置项接口
                api.getUserTianRun().then((res) => {
                  console.log("用户天润配置：", res.data);
                  if (res.data.code === 200) {
                    //隐藏电话条的登录界面
                    let params = {
                      identifier: res.data.result.enterpriseCode,
                      cno: res.data.result.cno,
                      password: res.data.result.cnoPassword,
                      bindTel: res.data.result.bindTel,
                      bindType: res.data.result.bindTelType,
                      loginStatus: 1,
                    };
                    ClinkAgent.login(params);
                    this.cno = res.data.result.cno;
                    //存储本地
                    localStorage.setItem(
                      "cno",
                      JSON.stringify(res.data.result.cno)
                    );
                    clearInterval(this.jin);
                    // //被踢下线
                    // apiShout.getBeiti({ cno: res.data.result.cno }).then((aa) => {
                    //   console.log("被踢：", aa.data);
                    //   if (aa.data.code == 200) {
                    //     //存本地
                    //     localStorage.setItem(
                    //       "initcno",
                    //       JSON.stringify(aa.data.result)
                    //     );
                    //   }
                    // });
                    // this.shout(res.data.result.cno);
                  }
                  // else {
                  //   that
                  //     .$http({
                  //       url: "/too/center/weblog/save",
                  //       params: {
                  //         callback: "",
                  //         errorCode: res.data.code,
                  //         errorExplain: res.data.msg,
                  //         interfaceUrl: res.config.url,
                  //         menuName: "客服系统-天润登录",
                  //         menuUrl: this.$route.path,
                  //         params: res.config.params,
                  //         source: "客服系统",
                  //         userId: ids,
                  //         userName: Names,
                  //       },
                  //       headers: {
                  //         "Content-Type": "application/json;charset=UTF-8",
                  //       },
                  //       method: "get",
                  //     })
                  //     .then((res) => {
                  //       if (res.data.code === 200) {
                  //         console.log();
                  //       }
                  //     });
                  // }
                });
                that.$message({
                  message: "客服登陆成功",
                  type: "success",
                });
                //token存储到vuex中
                this.setToken(res.data.result.token);
                //token 存储本地
                localStorage.setItem(
                  "token",
                  JSON.stringify(res.data.result.token)
                );
                //是否为客服保存本地
                localStorage.setItem(
                  "cusSer",
                  JSON.stringify(res.data.result.cusSerSign)
                );
                // that.$router.replace("/system_query/central").catch((err) => {
                //   console.log("err",err);
                // });
                //下面这行代码就是将登陆成功后端接口的数据转成字符串因为是对象，然后存到session给key
                localStorage.setItem(
                  "loginuser",
                  JSON.stringify(res.data.result)
                );
                sessionStorage.setItem(
                  "token",
                  JSON.stringify(res.data.result.token)
                );
                sessionStorage.setItem(
                  "name",
                  JSON.stringify(res.data.result.name)
                );
                sessionStorage.setItem(
                  "orgName",
                  JSON.stringify(res.data.result.orgName)
                );
                sessionStorage.setItem(
                  "photoUrl",
                  JSON.stringify(res.data.result.photoUrl)
                );
                sessionStorage.setItem(
                  "role",
                  JSON.stringify(res.data.result.role)
                );
                //console.log(res.data.result.token)
                that.loginmenu();
              } else if (res.data.code === 200) {
                that.$message({
                  message: "登陆成功",
                  type: "success",
                });
                clearInterval(this.jin);
                //token存储到vuex中
                this.setToken(res.data.result.token);
                //token 存储本地
                localStorage.setItem(
                  "token",
                  JSON.stringify(res.data.result.token)
                );
                // that.$router.replace("/system_query/central").catch((err) => {
                //   console.log("err",err);
                // });
                //下面这行代码就是将登陆成功后端接口的数据转成字符串因为是对象，然后存到session给key
                localStorage.setItem(
                  "loginuser",
                  JSON.stringify(res.data.result)
                );
                sessionStorage.setItem(
                  "token",
                  JSON.stringify(res.data.result.token)
                );
                sessionStorage.setItem(
                  "name",
                  JSON.stringify(res.data.result.name)
                );
                sessionStorage.setItem(
                  "orgName",
                  JSON.stringify(res.data.result.orgName)
                );
                sessionStorage.setItem(
                  "photoUrl",
                  JSON.stringify(res.data.result.photoUrl)
                );
                sessionStorage.setItem(
                  "role",
                  JSON.stringify(res.data.result.role)
                );
                //console.log(res.data.result.token)
                that.loginmenu();
              } else {
                that
                  .$http({
                    url: "/too/center/weblog/save",
                    params: {
                      callback: "",
                      errorCode: res.data.code,
                      errorExplain: res.data.msg,
                      interfaceUrl: res.config.url,
                      menuName: "客服系统-登录",
                      menuUrl: this.$route.path,
                      params: res.config.params,
                      source: "客服系统",
                      userId: "",
                      userName: "",
                    },
                    headers: {
                      "Content-Type": "application/json;charset=UTF-8",
                    },
                    method: "get",
                  })
                  .then((res) => {
                    if (res.data.code === 200) {
                      console.log();
                    }
                  });
                that.$message({
                  message: "登陆失败,请输入正确的用户名或密码",
                  type: "success",
                });
                that.$router.push("/");
              }

              // if (res.data.msg == '密码不正确') {
              //   that.$message.error("请输入正确密码");
              //   that.passworderror();
              // }else if(that.result > 2){
              //   if(that.ruleForm.code == ''){
              //     that.$message.error("请输入验证码");
              //   }
              // }else if(res.data.msg == "请输入正确的验证码"){
              //   that.$message.error("请输入正确的验证码");
              // }
              // else {
              //   that.$message({
              //     message: "登陆成功",
              //     type: "success"
              //   });
              //   that.$router.replace("/system_query/central");
              //   //下面这行代码就是将登陆成功后端接口的数据转成字符串因为是对象，然后存到session给key
              //   sessionStorage.setItem('loginuser',JSON.stringify(res.data.result));
              //   sessionStorage.setItem('token',JSON.stringify(res.data.result.token));
              //   //token存到vuex中

              //   sessionStorage.setItem('name',JSON.stringify(res.data.result.name));
              //   //console.log(res.data.result.token)
              //   //token存储到vuex中
              //  this.setToken(res.data.result.token);
              //  //token 存储本地
              //  localStorage.setItem('token',JSON.stringify(res.data.result.token));
              //   sessionStorage.setItem('name',JSON.stringify(res.data.result.name));
              //  // console.log(res.data.result.token)
              //   that.loginmenu();
              // }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      } else {
        alert("页面正在加载中，请稍后登录");
      }
    },
    // 点击图文
    imgtext() {
      this.graphicCode();
    },
    // 密码错误三次
    passworderror() {
      this.$http({
        url: "/too/login/errorCount",
        params: { name: this.ruleForm.name },
        method: "POST",
      }).then((res) => {
        console.log("密码错误三次", res);
        sessionStorage.setItem("result", JSON.stringify(res.data.result));
        if (res.data.result > 2) {
          this.show = false;
          this.graphicCode();
        }
      });
    },
    // 忘记密码
    forgetpwd() {
      this.$router.replace("./forgetpassword");
    },
    // 登陆成功菜单权限
    loginmenu() {
      let that = this;
      that.token = JSON.parse(sessionStorage.getItem("token"));
      //console.log('token',that.token)
      //console.log('token',that.token)
      that
        .$http({
          url: "/too/menu/getUserMenu",
          method: "POST",
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            //把token放到请求头才能请求，这里的'Bearer '表示是后台希望更加的安全，依据后台给的信息看到底是加还是不加
            // 'Authorization': 'Bearer ' + that.token,
            token: that.token,
          },
        })
        .then((res) => {
          //console.log("权限", res.data);
          if (res.data.code == 200) {
            sessionStorage.setItem(
              "jurisdiction",
              JSON.stringify(res.data.result)
            );
            //console.log('权限',res.data.result)
            //存储到vuex
            this.setMuenList({ menuList: res.data.result });
            //存储到本地
            window.localStorage.setItem(
              "houtai",
              JSON.stringify(res.data.result)
            );
            this.$router
              .push({ path: "/system_query/central" })
              .catch((err) => {
                console.log("err", err);
              });
          } else {
            let idNames = JSON.parse(localStorage.getItem("loginuser"));
            that
              .$http({
                url: "/too/center/weblog/save",
                params: {
                  callback: "",
                  errorCode: res.data.code,
                  errorExplain: res.data.msg,
                  interfaceUrl: res.config.url,
                  menuName: "客服系统-菜单权限",
                  menuUrl: this.$route.path,
                  params: res.config.params,
                  source: "客服系统",
                  userId: idNames.id,
                  userName: idNames.name,
                },
                headers: {
                  "Content-Type": "application/json;charset=UTF-8",
                },
                method: "get",
              })
              .then((res) => {
                if (res.data.code === 200) {
                  console.log();
                }
              });
          }
          if (res.data.code == 4001) {
            this.$router.push({ path: "@/Login.vue" }).catch((err) => {
              console.log("err", err);
            });
          }
        });
    },
  },
};
</script>
<style lang="less">
.logining {
  .header {
    margin-top: 100px;
    .imgs {
      text-align: center;
      img {
        width: 356px;
        height: 66px;
      }
    }
    .title {
      font-size: 40px;
      text-align: center;
      color: #91aca7;
      margin-top: 40px;
      letter-spacing: 8px;
      span {
        font-family: "ExtraLight";
      }
    }
  }
  .main {
    width: 580px;
    margin: 0 auto;
    .el-form {
      margin-top: 100px;
      .el-input__inner {
        width: 440px;
        border: transparent;
        border-radius: 0;
        border-bottom: 2px solid #d6d6d6;
        margin-bottom: 20px;
        margin-left: 25px;
        background: transparent;
      }
      .image {
        width: 34px;
        height: 43px;
      }
      .imagestext {
        display: flex;
        .el-input {
          width: 250px;
        }
        .el-input__inner {
          width: 200px;
        }
      }
    }
    .but {
      text-align: center;
      margin-top: 80px;
      .el-button {
        height: 66px;
        width: 190px;
        background: #d78673;
        font-size: 28px;
        border: none;
        border-radius: 33px;
      }
      .forgetpwd {
        cursor: pointer;
        display: block;
        margin-top: 80px;
        font-size: 23px;
        color: #92aca7;
      }
    }
  }
  // 弹框
  .el-dialog {
    width: 35%;
    .el-dialog__title {
      color: #92aca7;
    }
    .el-form-item__label {
      color: #a8a8a8;
      font-size: 17px;
    }
    .el-input {
      width: 350px;
    }
  }
  .pwdform {
    margin-left: 130px;
  }
}
</style>