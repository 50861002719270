
import axios from '../utils/request';
const base={
    baseUrl:'https://erp.careld.cn',
    login:'/too/login/login',//登录接口
    //Token验证
    checkTokens:'/too/login/checkToken',//Token验证
    qiniu:'/too/file/getQiNiuConf',//获取七牛存储配置项接口
    errorQiniu:'/too/file/uploadFile',//上传图片失败时调用
    changeUrl:'/too/user/updatePhotoByUserId',//修改用户头像路径接口
    loginMune:'/too/menu/getUserMenu',//3.3.查询登录用户角色权限菜单
    addMenu:'/too/menu/insertMenu',//添加菜单
    deleteMenu:'/too/menu/deleteMenu',//删除菜单
    detailsMenu:'/too/menu/getMenuInfo',//菜单详情
    getParentId:'/too/menu/getLevelMenu',//获取parentId
    menuList:'/too/menu/getMenuList',//菜单列表
    changeMenuList:'/too/menu/updateMenu',//修改菜单列表
    addOrg:'/too/org/getOrgList',//机构列表
    addOrgList:'/too/org/insertOrg',//添加列表机构
    changeOrg:'/too/org/updateOrg',//修改机构
    deleteOrg:'/too/org/deleteOrg',//删除机构
    searchOrg:'/too/org/getOrgInfo',//查询组织机构详情

    userList:'/too/user/getUserList',//用户列表
    addUserList:'/too/user/insertUser',//添加用户
    changeUser:'/too/user/updateUser',//修改用户
    deleteUser:'/too/user/deleteUser',//删除用户
    searchUser:'/too/user/getUserInfo',//查询用户详情

    roleList:'/too/role/getRoleList',//角色列表
    addRole:'/too/role/insertRole',//添加角色
    changeRole:'/too/role/updateRole',//修改角色
    deleteRole:'/too/role/deleteRole',//删除角色
    detailsRole:'/too/role/getRoleInfo',//角色详情
    allMenu:'/too/menu/getAllMenu',//获取所有菜单
    bangMenu:'/too/menu/insertRoleMenu',//配置角色绑定菜单
    roleAllMenu:'/too/menu/getRoleMenu',//获取角色所有菜单
    citys:'/too/city/getCity',//获取城市
    AreaCitys:'/too/city/getAreaCity',//获取城市
    getDictsIF:'/too/dictsLevel/getDictsInfo',//终端产品名称

    bigDictList:'/too/dict/getDictList',//大类字典列表


    xialaList:'/too/dicts/getDictsByDictCode',//查询字典大类下的小类
    allOrg:'/too/org/getAllOrg',//查询所有机构
    OrgRole:'/too/role/getOrgRoleByOrgId',//4.4.查询组织机构下所有角色

    userTianRun:'/too/user/getUserTianRun',//1.9.获取登录客服用户天润配置项接口
    allTypeUser:'/too/user/getOrgTypeUser',//1.3.查询机构下客服类型所有客服接口

    selectProduct:'/too/dicts/getDictsByDictCode',//产品管理下拉框
}

//请求方法
export const api={
    //产品管理下拉框
    getSelectProduct(params){
        return axios.post(base.selectProduct,params);
    },
    // 1.9.获取登录客服用户天润配置项接口
    getUserTianRun(){
        return axios.post(base.userTianRun);
    },
    //查询机构下客服类型所有客服接口
    getallTypeUser(params){
        return axios.post(base.allTypeUser,params);
    },
    // /**
    //  * 
    //  * 登录
    //  */
    // getLogin(params){
    //     return axios.post(base.login,params);
    // },
    /**
     * 获取七牛云配置
     */
    getQiuNiu(params){
        return axios.post(base.qiniu,params);
    },
    /**
     * 修改用户头像接口
     */
    changeUserPhoto(params){
        return axios.post(base.changeUrl,params);
    },
    /**
     * 动态路由
     */
    getDongLuYou(){
        return axios.post(base.loginMune);
    },
    /**
     *菜单列表
     */
    getMenuList(params){
        return axios.post(base.menuList,params);
    },
    /**
     * 删除菜单
     */
     deleteMenu(params){
        return axios.post(base.deleteMenu,params)
    },
    /**
     * 菜单详情
     */
    getDetailsMenu(params){
        return axios.post(base.detailsMenu,params);
    },
    /**
     * 机构列表
     */
    getOrgList(params){
        return axios.post(base.addOrg,params);
    },
    
    /**
     * 添加机构列表
     */
    getAddOrgList(params){
        return axios.post(base.addOrgList,params);
    },
    /**
     * 修改机构
     */
    getchangeOrg(params){
        return axios.post(base.changeOrg,params);
    },
    /**
     * 删除机构
     */
    deleteOrgList(params){
        return axios.post(base.deleteOrg,params);
    },
    /**
     * 查询组织机构查询
     */
    searchOrgList(params){
        return axios.post(base.searchOrg,params);
    },
    /**
     * 查询组织机构下所有角色
     */
    AllOrgRole(params){
        return axios.post(base.OrgRole,params);
    },
    /**
     * 查询所有机构
     */
    searchAllOrg(){
        return axios.post(base.allOrg);
    },
    /**
     * 用户列表
     */
    getUserList(params){
        return axios.post(base.userList,params);
    },
    /**
     * 添加用户
     */
    addUserList(params){
        return axios.post(base.addUserList,params);
    },
    /**
     * 修改用户
     */
    changeUserList(params){
        return axios.post(base.changeUser,params);
    },
    /**
     * 删除用户
     */
    deleteUserList(params){
        return axios.post(base.deleteUser,params);
    },
    /**
     * 查询用户详情
     */
    searchUserList(params){
        return axios.post(base.searchUser,params);
    },
    /**
     * 角色列表
     */
    getRoleList(params){
        return axios.post(base.roleList,params);
    },
    /**
     * 添加角色
     */
    addRoleList(params){
        return axios.post(base.addRole,params);
    },
    /**
     * 修改角色
     */
    changeRoleList(params){
        return axios.post(base.changeRole,params);
    },
    /**
     * 删除角色
     */
    deleteRoleList(params){
        return axios.post(base.deleteRole,params);
    },
    /**
     * 角色详情
     */
    detailsRoleList(params){
        return axios.post(base.detailsRole,params);
    },
    /**
     * 获取所有菜单
     */
    getAllMenu(){
        return axios.post(base.allMenu);
    },
    /**
     * 配置角色绑定菜单
     */
    bangMenuList(params){
        return axios.post(base.bangMenu,params);
    },
    /**
     * 获取角色所有菜单
     */
    getRoleAllMenu(params){
        return axios.post(base.roleAllMenu,params);
    },
    /**
     * 大类下的小类
     */
    smallLei(params){
        return axios.post(base.xialaList,params);
    },
    /**
     * 大类字典列表
     */
    bigDictList(params){
        return axios.post(base.bigDictList,params);
    },
    /**
     * 上传头像七牛云失败
     */
    errorQi(params){
        return axios.post(base.errorQiniu,params);
    },
    getCitys(params){
        return axios.post(base.citys,params)
    },
    AreaCity(params){
        return axios.post(base.AreaCitys,params)
    },
    //终端产品名称
    getDictsIFs(params){
        return axios.post(base.getDictsIF,params)
    },
    //验证token
    checkTokens(params){
        return axios.post(base.checkTokens,params)
    }
}

export default base