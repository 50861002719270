import Vue from 'vue'
import Vuex from 'vuex'
import LoginModule from './modules/LoginModule'
import routeModule from './modules/routeModule'
import Picture from './modules/picture'
import message from './modules/message'
import MatchedsModule from './modules/MatchedsModule'
import user from './modules/user' ;
import edition from './modules/edition';
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    LoginModule,
    routeModule,
    Picture,
    message,
    MatchedsModule,
    user,
    edition,
  }
})
