export default{
    namespaced:true,
    state:{
        mes:'',
    },
    mutations:{
        setMes(state,payload){
            state.mes=payload;
        },
        clearMes(state){
            state.mes='';

        }
    },
    actions:{}
}