//引入axios 
import axios from 'axios';
import qs from 'querystring';
import router from '@/router/index'

//响应错误处理方法 
/**
 * 常用的http协议状态码 
 * 2xx  成功
 * 3xx  4xx  5xx 
 * status 状态码
 * info:错误信息
 */
const errorHandle = (status, info) => {
    switch (status) {
        case 400:
            console.log('400-表示请求报文中存在语法错');
            break;
        case 401:
            console.log('401-未经许可，需要通过HTTP认证');
            break;
        case 403:
            console.log('403-服务器拒绝该次访问（访问权限出现问题）');
            break;
        case 404:
            console.log('404-表示服务器上无法找到请求的资源');
            break;
        case 500:
            console.log('500-表示服务器在执行请求时发生了错误，也有可能是web应用存在的bug或某些临时的错误时；');
            break;
        case 503:
            console.log('503-表示服务器暂时处于超负载或正在进行停机维护，无法处理请求');
            break;
        default:
            console.log(info);
            break;
    }
}

//创建实例
const instance = axios.create({
    //公共配置
    // baseURL: 'http://iwenwiki.com', //公共的请求的地址 
    timeout: 50000, //请求超时等待时间
})
/*
 * 拦截器
 */
//添加请求拦截器 
instance.interceptors.request.use(
    config => {
        //请求头里的token
        let token = JSON.parse(window.localStorage.getItem('token'));
        //功能：如果是post请求 处理发送的参数 
        if (config.method === 'post' && token) {
            //处理post发送的数据格式：config.data
            config.param = config.data;
            config.data = qs.stringify(config.data);
            config.headers.token = token;

        } else if (config.method === 'get' && token) {
            //处理get发送的数据格式：config.params
            config.paramsSerializer = function (params) {
                return qs.stringify(params)
            }
            // config.params = qs.stringify(config.params);
            config.headers.token = token;
        }
        return config;
    },
    error => Promise.reject(error)
)

//添加响应拦截器
instance.interceptors.response.use(
    //请求响应的成功数据
    response => {
        // console.log(response.status, 'response.status')
        let loginuser = JSON.parse(localStorage.getItem("loginuser"))
        let loginuserId = null;
        let loginuserName = "";
        // console.log(loginuserId, '用户id')
        // console.log(loginuserName, '用户名')
        let routerList = router.app._route;
        let projectName = "";

        projectName = "客服系统"
        loginuserId = loginuser.id;
        loginuserName = loginuser.name;
        let routerUrl = routerList.path;
        // console.log(routerUrl, '路由路径')
        let RouteName = [];
        routerList.matched.forEach(element => {
            if (Object.keys(element.meta).length != 0) {
                RouteName.push(element.meta.title);
            } else {
                RouteName = [];
            }
        });
        // console.log(RouteName.length, 'RouteName')
        let routerName = '';
        if (RouteName.length != 0) {
            routerName = RouteName[0] + '-' + RouteName[1]
        }
        // console.log(routerName, '路由名称')
        // console.log(projectName, routerUrl, routerName)
        if (response.status === 200) {
            // console.log(response, 'responses')
            // console.log(JSON.stringify(response.config.param), 'response.config.param')
            Promise.resolve(response)
            if (response.data.code == 10000) {
                console.log(response.data.msg, response.request.responseURL, response.data.code, response.config.param, 'token错误')
                let reg = new RegExp('=', 'g')
                let newMsg = JSON.stringify(response.config.data).replace(reg, ':');
                let regs = new RegExp('&', 'g')
                let newMsgs = JSON.stringify('{' + newMsg + '}').replace(regs, ',');
                axios.get('/too/center/weblog/save', {
                    params: {
                        callback: '',
                        errorCode: response.data.code,
                        errorExplain: response.data.msg,
                        interfaceUrl: response.config.url,
                        menuName: routerName,
                        menuUrl: routerUrl,
                        params: newMsgs,
                        source: projectName,
                        userId: loginuserId,
                        userName: loginuserName
                    }
                })
                    .then((res) => {
                        console.log(res, '成功')
                    })
                    .catch(error => {
                        console.log(error, '失败')
                    })
            } else if (response.data.code != 200) {
                console.log(response, response.data.msg, response.request.responseURL, response.data.code, response.config.data, response.config.param, 'token错误')
                axios.get('/too/center/weblog/save', {
                    params: {
                        callback: '',
                        errorCode: response.data.code,
                        errorExplain: response.data.msg,
                        interfaceUrl: response.config.url,
                        menuName: routerName,
                        menuUrl: routerUrl,
                        params: response.config.param,
                        source: projectName,
                        userId: loginuserId,
                        userName: loginuserName
                    }
                })
                    .then((res) => {
                        console.log(res, '成功')
                    })
                    .catch(error => {
                        console.log(error, '失败')
                    })
            }
            return response;
        } else {
            Promise.reject(response)
            console.log(response, 'errorresponse')
            return Promise.reject(response);
        }
    },
    error => {
        //接受错误里面的响应数据
        const { response } = error;
        console.log(response, 'response')
        let loginuser = JSON.parse(localStorage.getItem("loginuser"))
        let loginuserId = null;
        let loginuserName = "";
        // console.log(loginuserId, '用户id')
        // console.log(loginuserName, '用户名')
        let routerList = router.app._route;
        let projectName = "";

        projectName = "客服系统"
        loginuserId = loginuser.id;
        loginuserName = loginuser.name;
        let routerUrl = routerList.path;
        console.log(routerUrl, '路由路径')
        let RouteName = [];
        routerList.matched.forEach(element => {
            if (Object.keys(element.meta).length != 0) {
                RouteName.push(element.meta.title);
            } else {
                RouteName = [];
            }
        });
        // console.log(RouteName.length, 'RouteName')
        let routerName = '';
        if (RouteName.length != 0) {
            routerName = RouteName[0] + '-' + RouteName[1]
        }
        // console.log(routerName, '路由名称')
        // console.log(projectName, routerUrl, routerName)
        if (response.status == 500) {
            console.log(response.data.path, response.data.status, response.data.error, response.config.data, response.config.param, '错误状态吗')
            axios.get('/too/center/weblog/save', {
                params: {
                    callback: '',
                    errorCode: response.status,
                    errorExplain: response.data,
                    interfaceUrl: response.config.url,
                    menuName: routerName,
                    menuUrl: routerUrl,
                    params: response.config.param,
                    source: projectName,
                    userId: loginuserId,
                    userName: loginuserName
                }
            })
                .then((res) => {
                    console.log(res, '成功')
                })
                .catch(error => {
                    console.log(error, '失败')
                })

        } else if (response) {
            console.log(response, 'response')
            console.log(response.data, response.status, response.config.url, response.config.data, response.config.param, '500状态码')
            axios.get('/too/center/weblog/save', {
                params: {
                    callback: '',
                    errorCode: response.data.status,
                    errorExplain: response.data.error,
                    interfaceUrl: response.data.path,
                    menuName: routerName,
                    menuUrl: routerUrl,
                    params: response.config.param,
                    source: projectName,
                    userId: loginuserId,
                    userName: loginuserName
                }
            })
                .then((res) => {
                    console.log(res, '成功')
                })
                .catch(error => {
                    console.log(error, '失败')
                })
        }
        errorHandle(response.status, response.info)
    }
)
// 添加响应拦截器 
// instance.interceptors.response.use(function (response) {
//     // 对响应数据做点什么 
//     console.log(response,'responsess')
//     if(response.data.code == 40003){
//         console.log(response.data.msg,response.request.responseURL)
//     }
//     // return response;
// }, function (error) { // 对响应错误做点什么 
//     console.log(error,'errorsss')
//     return Promise.reject(error);
// });



//导出instance 
export default instance